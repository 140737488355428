@import "~@elastic/eui/src/theme_amsterdam_dark.scss";
@import "~@elastic/charts/dist/theme";
@import "data-grid-theme";
@import "~@uppy/core/dist/style.css";
@import "~@uppy/dashboard/dist/style.css";
@import "spacing";

.home-logo {
  img {
    width: 100%;
  }
}

.stats-table {
  .euiTableRow {
    &:first-child {
      background-color: rgba(35, 140, 255, 0.1);
      font-weight: bold;
    }
  }
}

.customRowClass {
  &:hover {
    background-color: rgba(35, 255, 163, 0.1);
  }
}

.cellSuccessColor {
  color: #2ecc71;
}

.cellDangerColor {
  color: #e74c3c;
}

.warning-color {
  color: $euiColorHighlight;
  background-color: $euiColorWarning;
  &::after {
    box-shadow: 0 1px 1px transparent;
  }

  &::before {
    box-shadow: 0 1px 1px transparent;
  }
}

.primary-color {
  color: $euiColorHighlight;
  background-color: $euiColorPrimary;
  &::after {
    box-shadow: 0 1px 1px transparent;
  }

  &::before {
    box-shadow: 0 1px 1px transparent;
  }
}

.success-color {
  color: $euiColorHighlight;
  background-color: $euiColorSecondary;
  &::after {
    box-shadow: 0 1px 1px transparent;
  }

  &::before {
    box-shadow: 0 1px 1px transparent;
  }
}

.hollow-color {
  color: $euiColorHighlight;
  background-color: $euiColorEmptyShade;
  &::after {
    box-shadow: 0 1px 1px transparent;
  }

  &::before {
    box-shadow: 0 1px 1px transparent;
  }
}

.text-color {
  color: $euiColorHighlight;
  background-color: $euiTextColor;
  &::after {
    box-shadow: 0 1px 1px transparent;
  }

  &::before {
    box-shadow: 0 1px 1px transparent;
  }
}

.no-border-radius {
  border-radius: 0 !important;
}

.no-border-left {
  border-left-width: 0px !important;
}

.euiSideNavItem--root + .euiSideNavItem--root {
  margin-top: 0;
}

.euiSuperDatePicker__flexWrapper {
  max-width: inherit;
  width: inherit;
  min-width: 606px;

  @include euiBreakpoint("xs", "s") {
    min-width: 100%;
  }
}
