$marginKey: "margin";
$paddingKey: "padding";
$separator: "-";
$sizes: (4, 8, 16, 24, 32, 40);
$positions: ("top", "right", "bottom", "left");

.no-#{$marginKey} {
  margin: 0 !important;
}

.no-#{$paddingKey} {
  padding: 0 !important;
}

@mixin size_spacings($suffix, $size) {
  $suffix: #{if($suffix, #{$separator}#{$suffix}, "")};

  .#{$marginKey}#{$separator}#{$size}#{$suffix} {
    margin: $size + px !important;
  }

  .#{$paddingKey}#{$separator}#{$size}#{$suffix} {
    padding: $size + px !important;
  }

  @each $position in $positions {
    .#{$marginKey}#{$separator}#{$position}#{$separator}#{$size}#{$suffix} {
      margin-#{$position}: $size + px !important;
    }

    .#{$paddingKey}#{$separator}#{$position}#{$separator}#{$size}#{$suffix} {
      padding-#{$position}: $size + px !important;
    }
  }
}

@mixin position_spacings($suffix, $position) {
  $suffix: #{if($suffix, #{$separator}#{$suffix}, "")};

  .no#{$separator}#{$marginKey}#{$separator}#{$position}#{$suffix} {
    margin-#{$position}: 0 !important;
  }

  .no#{$separator}#{$paddingKey}#{$separator}#{$position}#{$suffix} {
    padding-#{$position}: 0 !important;
  }
}

@each $position in $positions {
  @include position_spacings(null, $position);
}

@each $size in $sizes {
  @include size_spacings(null, $size);
}
